<script setup lang="ts">
import {TrustpilotWidgetConfig} from "~/types/TrustpilotWidgetConfig";

const props = defineProps<{
    config?: TrustpilotWidgetConfig;
}>();

const trustbox = ref<HTMLElement | null>(null);

onMounted(() => {
    if (
        process.client // To avoid SSR trying to do stuff.
        && window.Trustpilot && trustbox.value
    ) {
        window.Trustpilot.loadFromElement(trustbox.value);
    }
});

</script>

<template>
<div
    ref="trustbox"
    class="trustpilot-widget"
    :data-locale="config?.locale ?? 'da-DK'"
    :data-template-id="config?.templateId ?? '5419b637fa0340045cd0c936'"
    :data-businessunit-id="config?.businessunitId ?? '50004c540000640005181c98'"
    :data-style-height="config?.height ?? '28px'"
    :data-style-width="config?.width ?? '100%'"
    :data-theme="config?.theme ?? 'light'"
>
    <a
        href="https://dk.trustpilot.com/review/bevco.dk"
        target="_blank"
        rel="noopener"
    >
        Trustpilot
    </a>
</div>
</template>
